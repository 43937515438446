import * as React from 'react';
import { FC } from 'react';
import styles from './fileInfo.module.scss'
import { FileValue } from './FormDragFile';

export interface FileInfoProps {
    fileValue: FileValue,
    onRemove: () => void,
}

export const FileInfo: FC<FileInfoProps> = ({ fileValue, onRemove }) => {
    return (
        <div className={styles.dzFileInfo}>
            {fileValue.loading ?
                <div className={styles.loader}/>
                :
                <i className={'material-icons fas fa-file'}/>
            }
            <a className={styles.dzFileInfoName} href={fileValue.url} target={'_blank'}>
                <span>{fileValue.name}</span>
            </a>
            <a className={styles.remove} onClick={() => onRemove()}>
                <i className={'material-icons fas fa-times-circle'}/>
            </a>
        </div>
    )

}


