import * as React from 'react';
import { FC } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import styles from './formDragFile.module.scss'
import { FileInfo } from './FileInfo';

export interface FormDragFileProps {
    text: string;
    icon?: React.ReactNode;
    disabled: boolean;
    maxMBSize?: number,
    multiple?: boolean,
    maxFiles?: number,
    accept?: FileExtension | FileExtension[],
    error?: string,
    value: FileValue[],
    onDrop: (files: File[], errors: FileError[]) => void,
    onRemove: (id: string) => void,
}

export interface FileValue {
    id: string,
    name: string,
    url?: string,
    loading?: boolean,
}

export type FileError = 'file-too-large' | 'too-many-files' | 'file-invalid-type' ;

export enum FileExtension {
    XML = 'text/xml',
    PDF = 'application/pdf',
    PNG = "image/png",
    JPEG = "image/jpeg",
    JPG = "image/jpg",
    GIF = "image/gif",
    SVG = "image/svg",
}

export const FormDragFile: FC<FormDragFileProps> = ({
                                                        text,
                                                        icon,
                                                        accept,
                                                        maxMBSize,
                                                        maxFiles,
                                                        multiple,
                                                        error,
                                                        disabled,
                                                        value,
                                                        onDrop,
                                                        onRemove,
                                                    }) => {

    const onDropFiles = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        onDrop(acceptedFiles, fileRejections.map((fileRejection) => fileRejection.errors[0].code as FileError))
    }

    const hasValues = !!value && value.length > 0;

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDropFiles,
        accept,
        maxFiles: !multiple ? 1 : maxFiles,
        maxSize: maxMBSize ? 1024 * 1024 * maxMBSize : undefined,
        disabled,
        noClick: hasValues,
        noKeyboard: hasValues,
    })

    const renderContent = () => {
        if (!hasValues) {
            return (
                <React.Fragment>
                    {icon}
                    <p>{text}</p>
                </React.Fragment>
            );
        }
        return value.map((file) => <FileInfo fileValue={file} onRemove={() => onRemove(file.id)}/>)
    }

    return (
        <div className={styles.dz} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={styles.dzContainer} data-active={isDragActive} data-disabled={disabled}>
                {renderContent()}
            </div>
            <span className={styles.dzError}>{error}</span>
        </div>
    )

}


